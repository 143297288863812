import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Meta from '../components/meta'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const NotFound = ({ location }: Props) => {
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout location={location}>
      <Meta />

      <section className="main style1">
        <header className="small">
          <h2>Page Not Found</h2>
          <p>
            {`Sorry, can't find it. Perhaps a broken link?`}<br />
            {`Try one of these ${siteTitle} pages:`}<br />
            {[
                { label: 'Home', to: '/' },
                { label: 'EMRG: Projects', to: '/project/' },
                { label: 'EMRG: People', to: '/people/' },
                { label: 'EMRG: Courses', to: '/course/' },
                { label: 'EMRG: Collaboratories', to: '/collab/' },
              ].map((link, index) => (
                <>
                  <Link to={link.to} key={index}>{link.label}</Link>
                  <br />
                </>
            ))}
          </p>
        </header>
      </section>

    </Layout>
  )
}

export default NotFound
